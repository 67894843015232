import React, { useState } from 'react'
import { Props as ButtonProps } from '../../atoms/Button/Button'
import { Button } from '@next-nx/shared-ui/atoms'
import styles from './Dropdown.module.sass'


interface Props {
  triggerProps: ButtonProps
  contentPos?: 'left' | 'right'
  className?: string
  children?: React.ReactNode
}

const Dropdown = (props: Props) => {

  const { 
    triggerProps = {},
    contentPos = 'left',
    className = '',
    children = undefined,
  } = props

  const [open, setOpen] = useState(false)

  return (
    <div className={`${styles.dropdown} ${open ? styles['dropdown--open'] : ''} ${styles[`dropdown--content-${contentPos}`]} ${className ? ` ${className}` : ''}`}>
      <Button 
        variant="ghost"
        {...triggerProps}
        className={`${styles.trigger} ${triggerProps.className || ''}`}
        onClick={() => setOpen(!open)}
      />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default Dropdown
